/* src/components/Header.css */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  color: black;
  padding: 0 16px;
  height: 70px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
  position: relative;
}

.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

.header-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  margin: 0;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.auto {
  color: black;
  font-style: italic;
}

.match {
  color: #ff6600;
  font-style: italic;
}

.nav {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-login-button,
.header-logout-button,
.add-car-button {
  background-color: #ffffff;
  color: #ff7e5f;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.header-login-button:hover,
.header-logout-button:hover,
.add-car-button:hover {
  background-color: #ff7e5f;
  color: #ffffff;
}

.back-button-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.back-button {
  background: none;
  border: none;
  color: black;
  font-size: 1.7em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.back-button:hover {
  text-decoration: underline;
}

.header-right {
  display: flex;
  align-items: center;
}

.profile-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 6px;
  border: 2px solid white;
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.1);
}

.import-button {
  background-color: #28a745; /* Green color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.import-button:hover {
  background-color: #218838; /* Darker green on hover */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  .logo-container,
  .header-title,
  .header-right {
    position: static;
    transform: none;
    margin: 10px 0;
  }

  .header-login-button,
  .header-logout-button,
  .add-car-button,
  .import-button {
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
}
