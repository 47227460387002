/* src/components/SendSupport.css */
.send-support-container {
  background-color: #f5e6c1;
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.send-support-header {
  margin-bottom: 20px;
}

.send-support-header h1 {
  color: #000000;
  font-size: 1.5em;
  font-weight: bold;
}

.send-support-form p {
  color: #000000;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.send-support-textarea {
  width: 80%;
  height: 150px;
  padding: 12px;
  border: 1px solid #000000;
  border-radius: 12px;
  font-size: 1em;
  margin-bottom: 20px;
  resize: none; /* Disables resizing */
}

.send-support-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.send-support-button {
  background-color: #000000;
  width: 80%;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.send-support-button:hover {
  background-color: #002244;
}

.cancel-button {
  background-color: #cccccc;
  color: #333;
}

.cancel-button:hover {
  background-color: #999999;
}
