.add-car-container {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-car-container h2 {
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
  font-weight: 900;
}

.add-car-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group-horizontal {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.horizontal-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.horizontal-span {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.form-group label {
  font-weight: bold;
  color: #000000;
  margin-bottom: 8px;
}

.form-group input,
.horizontal-label input[type="checkbox"] {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
}

.form-group select {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='gray'%3E%3Cpath d='M4 6l4 4 4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
}

.date-inputs {
  display: flex;
  gap: 10px;
}

.horizontal-label input[type="checkbox"]:checked + .horizontal-span {
  background-color: #ff6600;
  color: #fff;
  border-color: #ff6600;
}

.horizontal-label input[type="checkbox"] {
  display: none;
}

.add-car-button {
  padding: 12px;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.2s ease-in-out;
  margin-left: 0px;
}

.add-car-button:hover {
  background-color: #002244;
}

/* AddressSearch Component Styles */
[role="combobox"] {
  position: relative;
  z-index: 1000;
}

.invalid-input {
  border-color: red;
}

.address-feedback {
  color: red;
  font-size: 0.9em;
  margin-top: 0.5em;
}
