.footer {
    background-color: #282c34;
    color: white;
    padding: 16px;
    text-align: center;
    bottom: 0;
  }
  
  .footer a {
    color: #61dafb;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }