/* src/components/Register.css */
.register-container {
    padding: 24px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .register-container h2 {
    color: #000000;
    margin-bottom: 16px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    color: #000000;
    margin-bottom: 8px;
  }
  
  .form-group input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1em;
  }
  
  .confirm-button {
    padding: 12px;
    font-size: 1.2em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    transition: background-color 0.2s ease-in-out;
    margin-top: 16px;
  }
  
  .confirm-button:hover {
    background-color: #002244;
  }
  