/* src/components/Login.css */
.login-container {
    padding: 24px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    border-bottom: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-container h2 {
    color: #000000;
    margin-bottom: 16px;
  }
  
  .login-container p {
    color: #666;
    margin-bottom: 24px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    color: #000000;
    margin-bottom: 8px;
  }
  
  .form-group input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1em;
  }
  
  .login-button,
  .google-button,
  .register-button {
    padding: 12px;
    font-size: 1.2em;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-top: 16px;
    width: 100%;
  }
  
  .login-button {
    background-color: #000000;
    color: #ffffff;
  }
  
  .login-button:hover {
    background-color: #002244;
  }
  
  .google-button {
    background-color: #db4437;
    color: #ffffff;
  }
  
  .google-button:hover {
    background-color: #c33d2e;
  }
  
  .register-button {
    background-color: #000000;
    color: #ffffff;
  }
  
  .register-button:hover {
    background-color: #002244;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 16px;
  }
  