.impressum-container {
    padding: 24px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #ffffff;
    text-align: center;
  }
  
  .impressum-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .back-button {
    background: none;
    border: none;
    color: #000;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .logo-image-wrapper {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .logo-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 10px solid #ff6600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .impressum-instructions {
    font-size: 1em;
    margin-bottom: 16px;
    color: #333;
  }
  
  .impressum-textarea {
    width: 100%;
    height: 150px;
    padding: 12px;
    font-size: 1em;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 16px;
  }
  
  .impressum-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  
  .impressum-button {
    background-color: #000000;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2em;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 300px;
  }
  
  .impressum-button:hover {
    background-color: #ff6600;
  }
  