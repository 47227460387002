.detail-container {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  border-bottom: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.back-button {
  background: none;
  border: none;
  color: #ff6600; /* CI-Farbe */
  font-size: 1.7em;
  cursor: pointer;
}

.back-button:hover {
  text-decoration: underline;
}

.detail-property-image {
  width: 100%;
  height: 300px; /* Standardhöhe für die Bilder */
  object-fit: cover; /* Bild wird skaliert, um den Rahmen zu füllen */
  object-position: center; /* Bild wird zentriert */
  border-radius: 8px;
  margin-bottom: 16px;
}

.detail-container h2 {
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
  font-weight: 900;
}

.property-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.property-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  margin-bottom: 8px;
}

.property-label {
  font-weight: bold;
  color: #000000; /* CI-Farbe */
  min-width: 150px;
}

.property-input {
  width: 100%;
  padding: 12px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
}

.button-group-centered {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff; /* White text for contrast */
  flex: 1; /* Make buttons equal width */
}

.save-button {
  background-color: #000000; /* CI-Farbe */
}

.cancel-button {
  background-color: #ff6600; /* CI-Farbe */
}

.images-button,
.edit-button {
  background-color: #000000; /* CI-Farbe */
}

.images-button,
.save-button:hover,
.cancel-button:hover,
.edit-button:hover {
  opacity: 1;
}

.form-group-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.horizontal-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.horizontal-label span:first-child {
  font-weight: bold;
  color: #000000; /* CI-Farbe */
}

.horizontal-span {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #ff6600; /* CI-Farbe */
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
  min-width: 50px;
  text-align: center;
}

.horizontal-span.no {
  background-color: #cccccc; /* Light grey for NO */
}

.date-inputs {
  display: flex;
  gap: 10px;
}

.date-inputs select {
  width: 100%; /* Make dropdowns take the full available width */
  padding: 12px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
}

.horizontal-label input[type="checkbox"]:checked + .horizontal-span {
  background-color: #ff6600;
  color: #fff;
  border-color: #ff6600;
}

.horizontal-label input[type="checkbox"] {
  display: none;
}

.add-car-button {
  padding: 12px;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.2s ease-in-out;
}

.add-car-button:hover {
  background-color: #002244;
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #ff6600; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
  z-index: 9999;
}

.expandable-header {
  background-color: #ff6600;
  color: white;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  position: relative;
  border-radius: 8px;
}

.expandable-section {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.expandable-header:hover {
  background-color: #21a1f1;
}

.expandable-header .arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
  transition: transform 0.3s ease;
  transform: rotate(0deg); /* Pfeil zeigt nach oben */
}

.expandable-header.open .arrow {
  transform: rotate(180deg); /* Pfeil zeigt nach unten */
}

.expandable-content {
  padding: 16px;
}

.details-delete-button {
  background-color: #cc0000; /* Rote Farbe für den Lösch-Button */
}

.details-delete-button:hover {
  background-color: #ff0000; /* Hellere rote Farbe beim Hover */
}

.delete-confirm-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-confirm-content {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.editable-container {
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.horizontal-label {
  display: flex;
  align-items: center;
  width: 100%;
}

.label-text {
  font-weight: bold;
  margin-right: 10px;
}

.editable-input {
  /* flex-grow: 1; */
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: right;
  max-block-size: fit-content;
  margin-left: auto;
}

.edit-icon {
  margin-left: 8px;
  color: grey;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

