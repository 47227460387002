/* src/components/GridView.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
}

.grid-item {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  text-decoration: none;
  color: inherit;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.grid-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(255, 102, 0, 0.3); /* #ff6600 mit 70% Transparenz */
}

.grid-item-image {
  width: 100%;
  height: 200px; /* Standardhöhe für die Bilder */
  object-fit: cover; /* Bild wird skaliert, um den Rahmen zu füllen */
  object-position: center; /* Bild wird zentriert */
  border-bottom: 3px solid #ff6600;
}

.grid-item-content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.grid-item-content h2 {
  color: #000000;
  font-size: 1.65em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-item-title {
  font-size: 1.5em;
  color: #000000;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
  border-bottom: 1px solid #c8c8c8;
}

.grid-item-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.grid-item-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1em;
  gap: 8px;
}

.grid-item-label {
  font-weight: bold;
  color: #000000; /* CI-Farbe */
}

.grid-item-value {
  text-align: right;
  flex: 1;
  white-space: nowrap; /* Verhindert Zeilenumbruch */
  overflow: hidden; /* Versteckt den überlaufenden Text */
  text-overflow: ellipsis; /* Fügt drei Punkte am Ende des Texts hinzu */
}

.grid-item-icon {
  color: #000000;
}

.placeholder-container {
  position: relative;
  text-align: left;
  padding: 50px 20px;
  background-image: url('../assets/background.jpg');
  background-size: cover;
  background-position: center;
  /* border-radius: 12px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin: 20px; */
  min-height: 1000px; /* Erhöht die Mindesthöhe des Containers */
  color: #ff6600; /* Textfarbe auf weiß setzen für bessere Lesbarkeit */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Text nach rechts schieben */
}

.placeholder-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.placeholder-content {
  position: relative;
  z-index: 2;
  max-width: 50%;
  text-align: left;
  background: rgba(0, 0, 0, 0.55); /* Halbtransparenter schwarzer Hintergrund */
  padding: 20px; /* Abstand innerhalb des Hintergrunds */
  border-radius: 8px; /* Abgerundete Ecken des Hintergrunds */
}

.placeholder-container h2 {
  color: #ff6600;
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.placeholder-container p {
  color: white;
  font-size: 1.5em;
}

.add-car-button {
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.add-car-button:hover {
  opacity: 0.9;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Volle Höhe des Viewports */
}

.fancy-button {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.fancy-button:hover {
  background-color: #ff4500;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.floating-add-car-button {
  position: fixed;
  bottom: 100px;
  right: 30px;
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 2em;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.floating-add-car-button:hover {
  background-color: #ff4500;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #ff6600; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
  z-index: 9999;
}