.images-container {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  border-bottom: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.images-container h2 {
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
  font-weight: 900;
}

.back-button {
  background: none;
  border: none;
  color: #ff6600; /* CI-Farbe */
  font-size: 1.7em;
  cursor: pointer;
}

.back-button:hover {
  text-decoration: underline;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.images-horizontal {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  padding: 16px 0;
}

.thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  image-orientation: from-image;
}

.image-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-item-horizontal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
}

.main-photo-label {
  position: absolute;
  bottom: 8px;
  left: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  line-height: 1;
}

.delete-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.upload-button:hover {
  opacity: 0.9;
}

.info-text {
  margin: 0 auto;
  text-align: center;
}

.save-order-button:hover {
  opacity: 0.9;
}


.upload-button,
.mode-toggle-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
}

.save-order-button {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
} 

.mode-toggle-button.active {
  background-color: #8f8f8f; /* CI-Farbe für aktiven Modus */
}

.images-upload-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
}
