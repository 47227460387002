.profile-container {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  border-bottom: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-image-large {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 16px;
  object-fit: cover;
  border: 10px solid #ff6600; /* Kreis um das Profilbild */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.profile-info {
  margin: 16px 0;
}

.profile-info label {
  font-weight: bold;
  color: #000000;
}

.profile-info p {
  color: #333;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.profile-button,
.logout-button,
.profile-delete-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 300px;
  margin: 0 auto; /* Center the button */
}

.profile-button:hover,
.logout-button:hover,
.profile-delete-button:hover {
  background-color: #ff6600;
}

.logout-button {
  background-color: #cccccc;
  color: #333;
  margin-top: 20px;
}

.profile-delete-button {
  background-color: #ff6666;
  color: white;
}

.profile-delete-button:hover {
  background-color: #ff3333;
}

.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: white;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  margin-top: 10px;
  width: 100%;
  max-width: 275px; /* Ensure it matches the button width */
}

.dropdown-container select {
  appearance: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.2em;
  width: 100%;
  cursor: pointer;
  padding: 0 24px;
  margin-left: -24px;
  outline: none; /* Remove the default outline */
}

.dropdown-container::after {
  content: '▼';
  position: absolute;
  right: 16px;
  pointer-events: none;
}

.dropdown-container select option {
  background-color: #ffffff;
  color: #333;
}
