/* src/components/SendFeedback.css */
.send-feedback-container {
    background-color: #f5e6c1;
    padding: 24px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .send-feedback-header {
    margin-bottom: 20px;
  }
  
  .send-feedback-header h1 {
    color: #000000;
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .send-feedback-form p {
    color: #000000;
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .send-feedback-textarea {
    width: 80%;
    height: 150px;
    padding: 12px;
    border: 1px solid #000000;
    border-radius: 12px;
    font-size: 1em;
    margin-bottom: 20px;
    resize: none; /* Disables resizing */
  }
  
  .send-feedback-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Center the buttons horizontally */
  }
  
  .send-feedback-button {
    background-color: #000000;
    width: 80%; /* Make buttons 80% of the container's width */
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2em;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .send-feedback-button:hover {
    background-color: #002244;
  }
  
  .cancel-button {
    background-color: #cccccc;
    color: #333;
  }
  
  .cancel-button:hover {
    background-color: #999999;
  }
  