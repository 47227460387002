.upload-images-container {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  /* background-color: #f5e6c1; */
  /* border-radius: 12px; */
  border-bottom: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.back-button {
  background: none;
  border: none;
  color: #ff6600; /* CI-Farbe */
  font-size: 1.7em;
  cursor: pointer;
}

.back-button:hover {
  text-decoration: underline;
}

.upload-images-container h2 {
  color: #000000;
  margin-bottom: 24px;
}

.file-input-container {
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input-label {
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #000000;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.file-input-label:hover {
  background-color: #000000;
  color: #ffffff;
}

.file-input-filename {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666666;
  max-height: 100px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  text-align: left;
}

.file-name {
  display: block;
  margin-bottom: 5px;
}

.upload-button {
  padding: 12px;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.2s ease-in-out;
  display: block;
  margin: 20px auto 0;
}



.upload-button:hover {
  background-color: #002244;
}

.progress-container {
  margin-top: 16px;
  text-align: center;
}

.progress-bar-container {
  margin-bottom: 16px;
  position: relative;
  height: 25px;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.progress-bar {
  width: 100%;
  height: 100%;
}

.progress-bar-fill {
  height: 100%;
  background-color: #000000; /* CI-Farbe */
  transition: width 0.2s ease-in-out;
}

.progress-bar-container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9em;
  color: #fff;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-top-color: #000000;
  animation: spin 1s ease-in-out infinite;
  display: block;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
